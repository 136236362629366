body,
#root,
div {
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  color: rgba(5, 34, 56, 0.87);
}

body {
  margin: 0;
  min-width: 1024px;
}
a {
  text-decoration: none;
}

.faq-download-icon {
  background-color: white;
}
