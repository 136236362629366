.recharts-xAxis .recharts-cartesian-axis-tick:first-child,
.recharts-xAxis .recharts-cartesian-axis-tick:last-child {
  display: none;
}

.chart-container {
  width: 1800px;
  height: 600px;
}

@media screen and (max-width: 1800px) {
  .chart-container {
    width: 1600px;
    height: 500px;
  }
}

@media screen and (max-width: 1600px) {
  .chart-container {
    width: 1450px;
    height: 500px;
  }
}

@media screen and (max-width: 1500px) {
  .chart-container {
    width: 1350px;
    height: 500px;
  }
}

@media screen and (max-width: 1400px) {
  .chart-container {
    width: 1200px;
    height: 450px;
  }
}

@media screen and (max-width: 1300px) {
  .chart-container {
    width: 1100px;
    height: 450px;
  }
}

@media screen and (max-width: 1200px) {
  .chart-container {
    width: 1000px;
    height: 450px;
  }
}

@media screen and (max-width: 1000px) {
  .chart-container {
    width: 900px;
    height: 450px;
  }
}
